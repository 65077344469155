<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Access Log"/>
        <v-spacer></v-spacer>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="4" sm="4">
              <v-text-field
                label="Search Username ..."
                v-model="options.filter.username"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
                clearable
              /> 
            </v-col>
            <v-col class="d-flex" cols="4" sm="4">
              <v-text-field
                label="Search URL ..."
                v-model="options.filter.url"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
                clearable
              /> 
            </v-col>
            <v-col class="d-flex" cols="4" sm="4">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="filter_date_formatted"
                  label="Transaction Date"
                  readonly
                  v-on="on"
                  @click:clear="clearFilterDaterange()"
                  hide-details
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.filter.date_range"
                @change="filterDatePicker = false"
                range
              ></v-date-picker>
            </v-menu>
            </v-col>
        </v-toolbar>
        <v-data-table 
            dense
            :items="accessLogs.data" 
            :headers="headers"
            sort-by="createdAt"
            :sort-desc="true"
            :loading="loadingDataTable"
            :server-items-length="totalResult"
            :options.sync="options"
            :footer-props="{
                  'items-per-page-options': [25,100, 500, 1000]
            }"
        >
            <template #item.createdAt="{value}">
              {{ moment(value).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
        </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'users',
  components: {
    pageHeading
  },
  data: () => ({
    dialog: false,
    options: {
        filter: {
          username: '',
          url: '',
          date_range : [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        sortBy: ['createdAt'],
        sortDesc: [true]
    },
    headers : [
        {text: "No.", value: 'id'},
        {text: "Username", value: 'user.email'},
        {text: "HTTP Method", value: 'request_method'},
        {text: "Page URL", value: 'ref_url'},
        {text: "Backend URL", value: 'api_url'},
        {text: "At", value: 'createdAt'},
    ],
    filterDatePicker: false,
    validate_rules : {
      required: v => !!v || 'This field is required',
      min: v => v.length >= 8 || 'Min 8 characters',
      email: v => /.+@.+\..+/.test(v) || 'Valid email pattern is required',
      password: v => {
        if(v){
          return ( v.length >= 8 || 'Min 8 characters');
        } else {
          return true
        }
      }
    }
  }),
  computed: {
    ...mapGetters(['canDo','accessLogs','loadingDataTable']),
    filter_date_formatted () {
      if(!this.options.filter.date_range) 
        return '';
        
      var date_format = this.options.filter.date_range.map(x => moment(x).format('DD-MM-YYYY'));
        return typeof date_format == 'object' ? date_format.join(' - ') : '';
    },
    totalResult(){
        return (this.accessLogs ? this.accessLogs.count : null) || 0;
    },
  },
  methods: {
    ...mapActions(['genCan', 'getAccessLogs']),
    clearFilterDaterange(){
      this.options.filter.date_range = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    },

  },
  watch: {
    options:{
        handler () {
          this.getAccessLogs(this.options);
        },
        deep: true,
    },
    filterDatePicker (val){
      if(val && this.options.filter.date_range.length == 0){
        this.options.filter.date_range = [ moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      }
    }
  },
  created() {
    this.genCan();
    this.getAccessLogs(this.options);
  }
};
</script>